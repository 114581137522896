import React from "react"
import loadable from '@loadable/component'
import CustomPlaceholder from './CustomPlaceholder'
const InnerWorkerSandbox = loadable(() => import('react-worker-sandbox'), {
    resolveComponent: (components) => components.WorkerSandbox,
    fallback: <CustomPlaceholder/>
})

function WorkerSandbox({...props}) {
    return(
        <div>
            <InnerWorkerSandbox {...props} />
        </div>
    );
}

export default WorkerSandbox;