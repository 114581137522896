import React from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import {
  Container,
  Header,
  Segment,
  Grid,
  Label,
  List,
  Icon,
  Popup,
} from "semantic-ui-react"

import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

import { Link } from "gatsby"
import _ from "lodash"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { formatSpecSection, getSpecSectionTitle } from "../components/spec_utils"
//import logo from "../images/progmiscon-logo-64.png"

import EmbedYoutube from "../components/EmbedYoutube"
import ExpressionTreeViewer from "../components/ExpressionTreeViewer"
import WorkerSandbox from "../components/WorkerSanbox";
import ScratchBlocksMDX from "../components/ScratchBlocksMDX";

const shortcodes = { EmbedYoutube, ExpressionTreeViewer, WorkerSandbox, ScratchBlocksMDX }

export default function MisconceptionPageTemplate({ data }) {
  const { mdx, specsMdx, concepts, zoteroItems } = data;
  
  const misconceptionConcepts = mdx.frontmatter.concepts
      ? mdx.frontmatter.concepts.filter(c => _.find(concepts.nodes, {'name': c}))
      : [];
 
  // Build a mapping from citation keys to Zotero keys (used in Zotero URLs / API),
  // e.g. qianStudentsMisconceptionsOther2017a ---> KALTHHG9
  const zoteroReferences = {};
  zoteroItems.nodes.forEach(node => {
    zoteroReferences[node.citationKey] = node.key;
  });
  
  return (
    <Layout>
      <SEO 
        title={mdx.fields.name} 
        cardTitle={`Misconception: ${mdx.fields.name}`}
        imageRelativeUrl={mdx.fields.image ? mdx.fields.image.childImageSharp.resize.src : null} 
        description={mdx.frontmatter.shortDescription}
        useLargeImageCard={true}
      />
      <Container
        style={{
          marginTop: "100px",
          marginBottom: "100px",
          background: "#faefdb",
        }}
      >
        <Segment inverted color="red" vertical style={{ padding: "1em 1em" }}>
          <Header as="h1" inverted>
            <Header.Content>
              {mdx.fields.name}
              {mdx.frontmatter.status==="deprecated" ? 
                <Label
                  color="black"
                >
                  Deprecated
                </Label>
                : null
              }
              {mdx.frontmatter.status==="draft" ? 
                <Label
                  color="yellow"
                >
                  DRAFT
                </Label>
                : null
              }
              {mdx.frontmatter.observed==="no" ? 
                <Label
                  color="black"
                >
                  Not Observed
                </Label>
                : null
              }
            </Header.Content>
          </Header>
        </Segment>
        <Segment
          style={{ marginTop: '0', marginBottom: '0', padding: "1em 1em", rbackground: "#f2e3be" }}
          inverted
          secondary
          color='red'
          basic
          id='misconception-body'
        >
          <div style={{fontWeight: 'bold', paddingBottom: '5px'}}>Misconception:</div>
          <MDXProvider components={shortcodes}>
            <MDXRenderer>{mdx.body}</MDXRenderer>
          </MDXProvider>
        </Segment>
        <Grid className="relaxed">
          <Grid.Row>
            <Grid.Column width={10} style={{ paddingRight: 0 }}>
              {mdx.fields.image ? (
                <Image
                  className="ui image"
                  fluid={mdx.fields.image.childImageSharp.fluid}
                  alt={mdx.fields.name}
                  style={{ width: "10242px" }}
                />
              ) : null}
              <div style={{padding: '1em'}}>
                <Grid className="relaxed">
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <div className="ui inverted raised red segment">
                        <div className="ui header">
                          Incorrect
                        </div>
                        <p>
                          {mdx.frontmatter.shortDescription}
                        </p>                        
                      </div>
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <div className="ui inverted raised green segment">
                        <div className="ui header">
                          Correct
                        </div>
                        <p>
                          {mdx.frontmatter.shortCorrection}
                        </p>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
              <Segment
                style={{ padding: "1em 1em", rbackground: "#f2e3be" }}
                vertical
              >
                {mdx.fields.correction &&
                mdx.fields.correction.childMdx.rawBody ? (
                  <>
                    <Header as="h2" dividing>
                      {/* 
                        Using className instead of name to avoid semantic-ui-react from 
                        complaining about unknown icon name. 
                        The "tools" icon does exist in fomantic-ui 2.8.
                      */}
                      <Icon className="tools" />
                      <Header.Content>
                        Correction
                        <Header.Subheader>
                          Here is what's right.
                        </Header.Subheader>
                      </Header.Content>
                    </Header>
                    <MDXProvider components={shortcodes}>
                      <MDXRenderer>{mdx.fields.correction.childMdx.body}</MDXRenderer>
                    </MDXProvider>
                  </>
                ) : null}
                {mdx.frontmatter.observed==="no" || (mdx.fields.origin &&
                mdx.fields.origin.childMdx.rawBody) ? (
                  <>
                    <Header as="h2" dividing>
                      <Icon className="map marker alternate" />
                      <Header.Content>
                        Origin
                        <Header.Subheader>
                          Where could this misconception come from?
                        </Header.Subheader>
                      </Header.Content>
                    </Header>
                    {mdx.frontmatter.observed==="no" ? ( 
                      <Segment inverted color="black" vertical style={{ padding: "1em 1em", marginBottom: "1em" }}>
                        <b>NOTE &mdash;&nbsp;</b>
                        We are not aware of repeated observations of this misconception in {mdx.fields.pl}.
                        We documented it,
                        for completeness,
                        based on observations of an equivalent misconception in other languages.
                      </Segment>
                    ) : null}
                    {mdx.fields.origin &&
                    mdx.fields.origin.childMdx.rawBody ? (
                      <MDXProvider components={shortcodes}>
                        <MDXRenderer>{mdx.fields.origin.childMdx.body}</MDXRenderer>
                      </MDXProvider>
                    ) : null}
                  </>
                ) : null}
                {mdx.fields.symptoms &&
                mdx.fields.symptoms.childMdx.rawBody ? (
                  <>
                    <Header as="h2" dividing>
                      <Icon name="heartbeat" />
                      <Header.Content>
                        Symptoms
                        <Header.Subheader>
                          How do you know your students might have this
                          misconception?
                        </Header.Subheader>
                      </Header.Content>
                    </Header>
                    <MDXProvider components={shortcodes}>
                      <MDXRenderer>{mdx.fields.symptoms.childMdx.body}</MDXRenderer>
                    </MDXProvider>
                  </>
                ) : null}
                
                {mdx.fields.value &&
                mdx.fields.value.childMdx.rawBody ? (
                  <>
                    <Header as="h2" dividing>
                      <Icon className="hand holding heart" />
                      <Header.Content>
                        Value
                        <Header.Subheader>
                          How can you build on this misconception?
                        </Header.Subheader>
                      </Header.Content>
                    </Header>
                    <MDXProvider components={shortcodes}>
                      <MDXRenderer>{mdx.fields.value.childMdx.body}</MDXRenderer>
                    </MDXProvider>
                  </>
                ) : null}
              </Segment>
            </Grid.Column>
            <Grid.Column width={6} style={{ paddingLeft: 0 }}>
              <div
                style={{
                  padding: "1em",
                  background: "#faefdb",
                  rbackground: "#f2e3be",
                }}
              >
                {mdx.frontmatter.observed==="yes" ? 
                  <Label
                    color="black"
                  >
                    Reported in published research
                  </Label>
                  : null
                }

                {mdx.frontmatter.videoId ? (
                  <EmbedYoutube
                    id={mdx.frontmatter.videoId}
                  />
                ) : null}

                <Header as="h3">Language</Header>
                <Label.Group>
                  <Label color="red">{mdx.fields.pl}</Label>
                  {mdx.frontmatter.plVersion ? (
                    <>
                      {mdx.frontmatter.plVersion.from ? (
                        <Label 
                          as={Link}
                          to={`/specs/${mdx.frontmatter.plVersion.from}/`}
                          color="yellow"
                          image
                        >
                          since
                          <Label.Detail>
                            {mdx.frontmatter.plVersion.from}
                          </Label.Detail>
                        </Label>
                      ) : null}
                      {mdx.frontmatter.plVersion.to ? (
                        <Label 
                          as={Link}
                          to={`/specs/${mdx.frontmatter.plVersion.to}/`}
                          color="yellow"
                          image
                        >
                          until
                          <Label.Detail>
                            {mdx.frontmatter.plVersion.to}
                          </Label.Detail>
                        </Label>
                      ) : null}
                    </>
                  ) : null}
                  {
                    mdx.frontmatter.specificToNm ? (
                      <Popup
                        content={<><b>{mdx.fields.name}</b> is specific to the <b>{mdx.frontmatter.specificToNm}</b> notional machine.</>}
                        on='hover'
                        inverted
                        trigger={
                          <Label color="orange">
                            Notional Machine
                            <Label.Detail>
                              {mdx.frontmatter.specificToNm}
                            </Label.Detail>
                          </Label>
                        }
                      />
                    ) : null
                  }
                </Label.Group>
                <Label.Group style={{marginTop: '10px'}}>
                  {mdx.frontmatter.spec ? mdx.frontmatter.spec.map(spec => (
                    spec.sections.map(section => (
                      <Label 
                        key={`spec-${spec.id}-${section}`}
                        as={Link}
                        to={`/specs/${spec.id}/${section}/`}
                        color="red" 
                      >
                        {spec.id}
                        <Label.Detail>{`${formatSpecSection(section)} ${getSpecSectionTitle(specsMdx, spec.id, section)}`}</Label.Detail>
                      </Label>
                    ))
                  )) : null}
                </Label.Group>
                {misconceptionConcepts.length === 0 ? "" :
                  <>
                    <Header as="h3">Concepts</Header>
                    <Label.Group>
                      {misconceptionConcepts.map((c, index) => (
                        <Label
                          key={`concepts-${index}`}
                          as={Link}
                          to={`/concepts/${_.kebabCase(c)}/${mdx.fields.pl}/`}
                          color="brown"
                        >
                          {_.startCase(c)}
                        </Label>
                      ))}
                    </Label.Group>
                  </>
                }
                {
                  mdx.frontmatter.expressibleInNms && mdx.frontmatter.expressibleInNms.length !== 0
                ?
                  <>
                    <Header as="h3">Expressible In</Header>
                    <List selection>
                      {mdx.frontmatter.expressibleInNms.map(nm => (
                        <List.Item
                          key={nm}
                          as={Link}
                          to={`/notionalMachines/${nm.id}/`}
                        >
                          <List.Icon name='settings' size='large' verticalAlign='middle' />
                          <List.Content>
                            <List.Header>
                              <span style={{ color: "#4183c4" }}>{nm.id}</span>
                            </List.Header>
                            {nm.how}
                          </List.Content>
                        </List.Item>
                      ))}
                    </List>
                  </>
                :
                  null
                }
                {mdx.frontmatter.related ? (
                  <>
                    <Header as="h3">Related Misconceptions</Header>
                    <List selection verticalAlign="middle">
                      {mdx.frontmatter.related.map((mc, index) => (
                        <List.Item
                          as={Link}
                          to={`/misconceptions/${mdx.fields.pl}/${mc.id}/`}
                          key={`related-${index}`}
                        >
                          <List.Icon className="warning sign" size='large' verticalAlign='middle' />
                          <List.Content>
                            <List.Header>
                              {/* color this like a link, without producing a nested a element */}
                              <span style={{ color: "#4183c4" }}>{mc.id}</span>
                            </List.Header>
                            {mc.relation}
                          </List.Content>
                        </List.Item>
                      ))}
                    </List>
                  </>
                ) : null}
                {mdx.frontmatter.inOtherPl ? (
                  <>
                    <Header as="h3">Other Languages</Header>
                    <List selection verticalAlign="middle">
                      {mdx.frontmatter.inOtherPl.map((mc, index) => (
                        <List.Item
                          as={Link}
                          to={`/misconceptions/${mc.pl}/${mc.id}/`}
                          key={`related-${index}`}
                        >
                          <List.Icon className="warning sign" size='large' verticalAlign='middle' />
                          <List.Content>
                            <Label color="black" image>
                              {mc.pl}
                              <Label.Detail>{mc.id}</Label.Detail>
                            </Label>
                          </List.Content>
                        </List.Item>
                      ))}
                    </List>
                  </>
                ) : null}
                {mdx.frontmatter.references ? (
                  <>
                    <Header as="h3">Literature References</Header>
                    <List selection>
                      {mdx.frontmatter.references.map(reference => {
                        const refEntry = data.refs.edges.filter(edge => edge.node.slug === "references/"+reference.citationKey);

                        return (
                          <List.Item
                            as={Link}
                            to={`/reference/${reference.citationKey}`}
                            // href={`https://www.zotero.org/groups/2535417/progmiscon/items/${zoteroReferences[reference.citationKey]}`}
                            key={`zotero-${reference.citationKey}`}
                          >
                            <List.Icon className="file alternate outline" size='large' verticalAlign='middle' />
                            <List.Content>
                              <List.Header>
                                {/* color this like a link, without producing a nested a element */}
                                <span style={{ color: "#4183c4", wordBreak: "break-all" }}>{reference.citationKey}</span>
                              </List.Header>
                              <strong>{reference.id}:</strong> {reference.name}
                              {
                                reference.relationship ? <> ({reference.relationship})</> : null
                              }
                              {
                                refEntry.map((edge) => (
                                  <>
                                    <br/><em>{edge.node.frontmatter.method}</em>
                                    <br/>{edge.node.frontmatter.subjects}<br/>

                                    {
                                      edge.node.frontmatter.langs.map(lang => (
                                        <Label
                                          key={lang}
                                          color="red"
                                        >
                                        { lang }
                                        </Label>
                                      ))
                                    }
                                    {
                                      edge.node.frontmatter.artifact ? <span style={{ marginLeft: "1rem" }} data-tooltip="Artifact available" ><Icon className="brown archive" size="large" /></span> : null
                                    }
                                  </>
                                ))
                              }
                              <br/>
                            </List.Content>
                          </List.Item>
                        );
                      })}
                    </List>
                  </>
                ) : null}
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      rawBody
      fields {
        name
        pl
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
              src
            }
            resize(width: 1024, height: 512, fit: COVER, cropFocus: NORTH) {
              src
              height
            }
          }
        }
        correction {
          childMdx {
            body
            rawBody
          }
        }
        origin {
          childMdx {
            body
            rawBody
          }
        }
        symptoms {
          childMdx {
            body
            rawBody
          }
        }
        value {
          childMdx {
            body
            rawBody
          }
        }
      }
      frontmatter {
        shortDescription
        shortCorrection
        status
        observed
        videoId
        concepts
        related {
          id
          relation
        }
        inOtherPl {
          pl
          id
        }
        plVersion {
          from
          to
        }
        specificToNm
        expressibleInNms {
          id
          how
        }
        spec {
          id
          sections
        }
        references {
          citationKey
          id
          name
          relationship
        }
      }
    }

    specsMdx: allMdx(filter: { fileAbsolutePath: { glob: "**/src/data/specs/*.md" } }) {
      nodes {
        parent {
          ... on File {
            name
          }
        }
        frontmatter {
          pl
          sections {
            section
            title
            url
          }
        }
      }
    }

    concepts: allFile(filter: {absolutePath: {glob: "**/src/data/concepts/*.md"}}
    ) {
      nodes {
        name
      }
    }

    zoteroItems: allZoteroItems {
      nodes {
        key
        citationKey 
      }
    }
    
    refs: allMdx(filter: {frontmatter: {type: {eq: "ref"}}}) {
      edges {
        node {
          id
          slug
          frontmatter {
            langs
            method
            subjects
            artifact
          }
          fields {
            slug
            name
            pl
          }
        }
      }
    }
  }
`
