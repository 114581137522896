import React from "react"
import loadable from '@loadable/component'
import { Placeholder } from 'semantic-ui-react'

const InnerScratchBlocksMDX = loadable(() => import("./InnerScratchBlocksMDX"), {
    fallback: <Placeholder fluid style={{height: '800px'}}><Placeholder.Image/></Placeholder>
})

function ScratchBlocksMDX({...props}) {
    return(
        <div>
            <InnerScratchBlocksMDX {...props} style={{height: '800px'}}/>
        </div>
    );
}

export default ScratchBlocksMDX;